<template>
  <div class="card card-icon mb-4">
    <div class="row no-gutters">
      <!-- Icon -->
      <div
        class="d-none
            d-md-flex
            col-auto
            card-icon-aside
            bg-primary
            text-white"
      >
        <i data-feather="award"></i>
      </div>

      <div class="col">
        <b-overlay :show="isCreatingResource || isUpdatingResource">
          <template #overlay>
            <div class="text-center">
              <loading message="Procesando, por favor espere..."></loading>
            </div>
          </template>
          <div class="card-body py-4">
            <h3 class="card-title">
              <span :class="isEditingResource ? 'text-danger' : ''">{{ titleForm }}</span>
            </h3>

            <div class="card-text">
              <FormulateForm
                ref="prestacionCatalogoForm"
                @submit="submit"
                #default="{ hasErrors, isLoading }"
              >
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <!-- Clave -->
                    <x-form-group title="Clave" required>
                      <FormulateInput
                        v-model="formValues.clave"
                        type="text"
                        :disabled="isLoading"
                        placeholder="Ingresa la clave de prestación"
                        validation="bail|required|max:100,length"
                        :validation-messages="{
                          required: 'La clave es requerida',
                          max: 'La longitud máxima es de 100 caracteres'
                        }"
                      />
                    </x-form-group>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <!-- Nombre -->
                    <x-form-group title="Nombre" required>
                      <FormulateInput
                        v-model="formValues.nombre"
                        :disabled="isLoading"
                        placeholder="Ingresa el nombre de la prestación"
                        validation="bail|required|max:255,length"
                        :validation-messages="{
                          required: 'El nombre de la prestación es requerido',
                          max: 'La longitud máxima es de 255 caractéres'
                        }"
                      />
                    </x-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <!-- Periodo fiscal -->
                    <FormulateInput
                      name="periodo_fiscal"
                      v-model="formValues.periodo_fiscal_id"
                      :disabled="isLoading"
                      validation="bail|required"
                      error-behavior="live"
                      :validation-messages="{
                        required: 'El periodo fiscal es requerido'
                      }"
                    >
                      <template #element>
                        <x-form-group title="Periodo fiscal" required>
                          <check-authorization
                            :requiresAuthorizations="['listar periodos fiscales']"
                            :show-alert="false"
                            no-slots
                            #default="{ authorized, message }"
                          >
                            <span v-b-tooltip.top :title="authorized ? '' : message">
                              <x-select
                                v-model="formValues.periodo_fiscal_id"
                                dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                                getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                                :formatListWith="['id', 'nombre']"
                                defaultText="-- Selecciona un periodo fiscal --"
                                no-prepend
                                no-validate
                                :disabled="!authorized"
                              ></x-select>
                            </span>
                          </check-authorization>
                        </x-form-group>
                      </template>
                    </FormulateInput>
                  </b-col>

                  <b-col cols="12" md="6" lg="6">
                    <!-- Partida -->
                    <FormulateInput
                      name="partida"
                      v-model="formValues.partida_id"
                      :disabled="isLoading"
                      validation="bail|required"
                      error-behavior="live"
                      :validation-messages="{
                        required: 'La partida es requerida'
                      }"
                    >
                      <template #element>
                        <x-form-group title="Partida" required>
                          <x-select
                            ref="xselectpartidas"
                            v-model="formValues.partida_id"
                            :dispatchPath="null"
                            getterListPath="partidaModule/getPartidas"
                            :formatListWith="['id', 'clave_nombre']"
                            defaultText="-- Selecciona una partida --"
                            no-prepend
                            no-validate
                          />
                        </x-form-group>
                      </template>
                    </FormulateInput>
                  </b-col>
                </b-row>

                <!-- Mes pago -->
                <FormulateInput
                  name="mes_pago"
                  v-model="formValues.mes_pago"
                  :disabled="isLoading"
                  validation="bail|required"
                  error-behavior="live"
                  :validation-messages="{
                    required: 'El pago del mes es requerido'
                  }"
                >
                  <template #element>
                    <x-form-group title="Mes pago" required>
                      <x-calendar v-model="formValues.mes_pago"></x-calendar>
                    </x-form-group>
                  </template>
                </FormulateInput>

                <!-- Estatus -->
                <b-form-group>
                  <b-form-checkbox
                    v-model="formValues.estatus"
                    switch
                    inline
                    class="my-2 user-select-none"
                  >
                    Estado
                  </b-form-checkbox>
                </b-form-group>

                <!-- Enviar para crear o actualizar -->
                <x-form-footer-buttons
                  :disabled="hasErrors || isCreatingResource || isUpdatingResource"
                  :loading="isCreatingResource || isUpdatingResource"
                  :isEditing="isEditingResource"
                  :isCreating="!isEditingResource"
                  @on-cancel="resetForm"
                  @on-click-create="submit"
                  @on-click-update="submit"
                ></x-form-footer-buttons>
              </FormulateForm>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import XSelect from '@/components/Shared/XSelect'
import XCalendar from '@/components/Shared/XCalendar'
import { mapGetters, mapState } from 'vuex'

const formValues = () => ({
  clave: '',
  nombre: '',
  mes_pago: '',
  estatus: false,
  partida_id: null,
  periodo_fiscal_id: null
})

export default {
  name: 'PrestacionesForm',

  components: {
    XSelect,
    XCalendar
  },

  mounted () {
    this.getPartidas()
  },

  data () {
    return {
      formValues: formValues()
    }
  },

  computed: {
    ...mapState('prestacionesModule', [
      'currentResource',
      'isCreatingResource',
      'isUpdatingResource'
    ]),

    ...mapGetters('prestacionesModule', ['isEditingResource']),

    titleForm () {
      return this.isEditingResource
        ? `Editando: ${this.currentResource.nombre}`
        : 'Crear prestación'
    }
  },

  watch: {
    currentResource: {
      immediate: true,
      handler (val) {
        if (!val) {
          this.formValues = formValues()
          return
        }

        this.formValues = val
      }
    }
  },

  methods: {
    emitOnHideForm () {
      this.$emit('hide-form')
    },

    emitOnSubmitted () {
      this.$emit('submitted')
    },

    async getPartidas () {
      try {
        this.$refs.xselectpartidas.loading = true
        const periodoActivo = await this.$getPeriodoFiscalActivo()

        const { error, message } = await this.$store.dispatch(
          'partidaModule/getAll',
          periodoActivo.id
        )
        this.$refs.xselectpartidas.loading = false

        if (error) this.$notify({ error, message }, 'Listado de partidas')
      } catch (_) {}
    },

    async submit () {
      const getval = this.isEditingResource
        ? await this.update(this.formValues)
        : await this.create(this.formValues)

      if (getval.error) return this.$notify(getval, 'Envío de formulario')

      this.emitOnSubmitted()
      this.resetForm()
    },

    async create (form) {
      return await this.$store.dispatch('prestacionesModule/create', form)
    },

    async update (form) {
      return await this.$store.dispatch('prestacionesModule/updateById', {
        id: form.id,
        payload: form
      })
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit('prestacionesModule/setCurrentResource', null)
      this.formValues = formValues()
      if (this.$refs.prestacionCatalogoForm) {
        this.$refs.prestacionCatalogoForm.hideErrors()
      }
      this.emitOnHideForm()
      window.scrollTo(0, 0)
    }
  }
}
</script>
