<template>
  <div class="border border-gray-200 rounded p-2">
    <div>
      <!-- Meses seleccionados -->
      <div>
        <b-alert variant="danger" :show="Boolean(message)" dismissible>{{ message }}</b-alert>

        <label for="tags-basic" class="small font-weight-bolder">
          Rangos y meses seleccionados
        </label>
        <b-form-group v-if="arr.length > 0" class="border rounded py-2 px-3 d-flex flex-wrap">
          <b-badge
            v-for="item in arr"
            :key="item.id"
            class="ml-1 mb-1 my-cursor"
            @click="removeItem(item)"
          >
            {{ item }}
          </b-badge>
        </b-form-group>

        <!-- Rangos de fechas seleccionados -->
        <b-form-group>
          <b-badge v-if="Boolean(firstRange)" variant="primary" class="mr-2">
            Inicio: {{ firstRange }}
          </b-badge>
        </b-form-group>
      </div>

      <!-- Switch -->
      <b-form-group v-if="!isRecurrente">
        <b-form-checkbox v-model="range" switch inline class="my-2 user-select-none">
          Seleccionar un rango de fechas
        </b-form-checkbox>
      </b-form-group>

      <!-- Calendario -->
      <b-form-group v-if="!isRecurrente">
        <template #label>
          <label class="small font-weight-bolder">
            Meses
          </label>
        </template>

        <b-row class="" cols="12" cols-lg="12" cols-md="6" cols-sm="1">
          <b-col
            v-for="item in getMonths"
            :key="item.id"
            class="text-center my-cursor"
            @click="addMonth(item)"
          >
            <div
              class="calendar__item text-sm border border-gray-400 rounded py-2 mb-2"
              :class="isSelected(item)"
            >
              <span
                :class="
                  item.value === 'INCIDENTAL'
                    ? 'text-warning'
                    : item.value === 'RECURRENTE'
                    ? 'text-warning'
                    : ''
                "
              >
                {{ item.value }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'XCalendar',

  props: {
    value: {
      default: []
    }
  },

  data () {
    return {
      range: false,

      message: '',

      firstRange: null,
      lastRange: null,

      arr: []
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value === '') {
          this.arr = []
          return
        }

        this.mapMesPago(value)
      }
    },

    arr: {
      deep: true,
      immediate: true,
      handler (value) {
        const retval = _.join(value, ',')

        this.emit(retval)
      }
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonths']),

    isRecurrente () {
      return this.arr.includes('RECURRENTE')
    }
  },

  methods: {
    /**
     * Agrega un mes individualmente.
     *
     * Agrega un mes individualmente, siempre y cuando no esté duplicado.
     */
    addMonth (month) {
      if (month.value === 'RECURRENTE') {
        if (this.isDuplicate('RECURRENTE')) {
          this.message = 'Ya se seleccionó el periodo como recurrente.'
          return
        }

        // this.arr = this.getMonths.filter(el => el.id <= 12).map(el => el.value)
        this.arr = []
        this.arr.push(month.value)
        this.reset()
        return
      }

      //
      // Validación de periodo recurrente
      if (this.isDuplicate('RECURRENTE')) {
        this.message = 'Ya se seleccionó el periodo como recurrente.'
        return
      }

      if (this.range) return this.addMonthByRange(month)

      if (this.isDuplicate(month.value)) {
        this.message = `El mes: ${month.value} seleccionado ya está ingresado`
        return
      }

      this.arr.push(month.value)
    },

    /**
     * Agrega un rango de meses.
     *
     * Agrega un rango de meses siempre y cuando el rango no esté agregado,
     * además de que el último mes no sea mayor al mes de inicio de rango seleccionado.
     */
    addMonthByRange (month) {
      if (!this.firstRange) {
        this.firstRange = month.value
        return
      }

      this.lastRange = month.value

      if (!this.validRangeOfMonths(this.firstRange, this.lastRange)) {
        this.message = 'Rango de meses incorrecto, el último mes debe ser mayor que el inicial.'
        this.firstRange = null
        this.lastRange = null
        return
      }

      const composition = `${this.firstRange}-${this.lastRange}`

      if (this.isDuplicate(composition)) {
        this.message = `El rango seleccionado: ${composition} ya está agregado`
        this.firstRange = null
        this.lastRange = null
        return
      }

      this.arr.push(composition)

      this.reset()
    },

    /**
     * Valida el rango de meses seleccionados.
     *
     * Valida en base a una matriz de objetos, dependiendo su valor
     * que el último mes seleccionado no sea mayor al mes de inicio seleccionado.
     */
    validRangeOfMonths (first, last) {
      const idStart = this.getMonths.find(el => el.value === first).id
      const idLast = this.getMonths.find(el => el.value === last).id

      return idStart < idLast
    },

    /**
     * Valida si el mes o el rango de mes ya está agregado.
     */
    isDuplicate (item) {
      return this.arr.includes(item)
    },

    /**
     * Valida si el mes que se muestra ya está en el array,
     * si es así entonces devuelve el nombre de una clave para aplicarla.
     *
     * Si la opción de seleccionar por rango está activado, termina y llama a otra función.
     */
    isSelected (item) {
      if (this.range) return this.isSelectedByRange(item)

      return this.arr.includes(item.value) ? 'is__selected' : ''
    },

    /**
     * Valida si el primer mes seleccionado en modo de "rango" está seleccionado,
     * si es así entonces devuelve una clase para aplicar.
     */
    isSelectedByRange (item) {
      return this.firstRange === item.value || this.lastRange === item.value
        ? 'is__first_selected'
        : ''
    },

    /**
     * Elimina un elemento de los que están seleccionados.
     */
    removeItem (item) {
      this.arr = this.arr.filter(el => el !== item)
    },

    mapMesPago (value) {
      const months = value.split(',')

      if (months.length <= 1) {
        this.arr = []
        this.arr.push(value)
        return
      }

      this.arr = months.map(el => el)
    },

    reset () {
      this.firstRange = null
      this.lastRange = null
      this.message = ''
      this.range = false
    },

    /**
     * Emite los nuevos valores del array.
     */
    emit (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
$color: #30256e;

.is__selected {
  background-color: $color;
  color: #e7e7e7;
}

.calendar__item {
  &:hover {
    @extend .is__selected;
  }
}

.is__first_selected {
  background-color: #00c71b;
  color: white;
}
</style>
