<template>
  <base-view icon="award" title="Prestaciones">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- Formulario para las prestaciones -->
    <check-authorization
      :requiresAuthorizations="['crear prestaciones']"
      :overrideIf="isEditingResource"
      hide
    >
      <prestaciones-form
        v-if="showForm || isEditingResource"
        @hide-form="cancel"
        @submitted="getListadoPrestaciones"
      />
    </check-authorization>

    <!-- Listado de prestaciones -->
    <check-authorization :requiresAuthorizations="['listar prestaciones']">
      <div class="card card-header-actions">
        <div class="card-header">
          Prestaciones
          <x-btn-with-auth
            :requiresAuthorizations="['crear prestaciones']"
            :callback="cancel"
            variant="success"
            btnClass="ml-auto"
          >
            {{ !showForm ? 'Crear' : 'Ocultar' }}
          </x-btn-with-auth>
        </div>

        <div class="card-body p-2">
          <prestaciones-list @hideForm="showForm = true" @input="onChangePeriodoFiscal" />
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PrestacionesForm from '@/components/Prestaciones/PrestacionesForm'
import PrestacionesList from '@/components/Prestaciones/PrestacionesList'

export default {
  name: 'Prestaciones',

  components: {
    PrestacionesForm,
    PrestacionesList
  },

  computed: {
    ...mapGetters('prestacionesModule', ['isEditingResource'])
  },

  data () {
    return {
      showForm: false,

      periodo_fiscal_id: null
    }
  },

  methods: {
    ...mapActions('prestacionesModule', ['getAll']),

    async getListadoPrestaciones () {
      const { error, message } = await this.getAll(this.periodo_fiscal_id)

      if (error) this.$notify({ error, message }, 'Listado de prestaciones')
    },

    cancel () {
      this.showForm = !this.showForm
    },

    onChangePeriodoFiscal (periodo_fiscal_id) {
      this.periodo_fiscal_id = periodo_fiscal_id

      this.getListadoPrestaciones()
    }
  }
}
</script>
